'use strict'

###*
 # @ngdoc object
 # @name mundoReporting.service:ReportTabService

 # @description

###
class ReportTabService
  ### @ngInject ###
  constructor:(
    $log
    $filter
    $q
    $window
    uuid4
  ) ->

    @createNewTab = (reportSource, visualizeFilters = null) ->
      # Create Array-vars for fields, because they are not in Array-format.
      # Loop all the fields and filters to put them in those Arrays.
      reportSource = angular.copy reportSource

      fieldsArray = new Array()
      filtersArray = new Array()
      angular.forEach reportSource.fields, (field) ->
        fieldsArray.push field
      angular.forEach reportSource.filters, (filter) ->
        filtersArray.push filter
      reportSource.visualizeFilters = visualizeFilters

      # Set the tab-object in the correct format.
      tab = {
        code: reportSource.code
        text: reportSource.label
        sourcefields: fieldsArray
        sourcefilters: filtersArray
        source: reportSource
        id: uuid4.generate()
      }

      # Check if the source already has a configuration.
      # If it has one it will be added.
      # Else it will be skipped.
      if reportSource.configuration
        tab['configuration'] = reportSource.configuration

      # Return the tab.
      tab

angular
  .module('mundoReporting')
  .service 'ReportTabService', ReportTabService
